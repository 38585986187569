<template>
  <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
    Memuatkan butiran murid
  </loading-spinner>
  <dependant-details v-else-if="currentDependant" :currentDependant="currentDependant" @update="updateDependant"
    @close="viewDependant" />
  <div v-else-if="!isLoading">
    <div class="p-3 bg-blue-dark text-light rounded shadow-sm border d-flex align-items-center">
      <button class="btn btn-sm py-0 me-3 lh-1" @click="backButton()">
        <i class="fas fa-arrow-left text-light" />
      </button>
      <p class="mb-0 text-truncate fw-medium lh-1" v-if="currentClass">
        {{ currentClass.name }}
      </p>
      <span class="text-muted fst-italic" v-else>Tiada kelas dipilih</span>
    </div>
    <div class="py-3 text-nowrap overflow-auto no-scrollbar" v-if="currentClass">
      <div class="pb-3 d-flex justify-content-between align-items-center">
        <div class="input-group">
          <span class="input-group-text bg-white">
            <i class="fas fa-search" />
          </span>
          <input type="text" class="form-control border-start-0 ps-0 no-focus"
            :class="{ 'border-end-0': search.length > 0 }" placeholder="Cari murid" v-model="search" />
          <button class="btn bg-white border border-light" type="button" v-if="search.length > 0" @click="search = ''">
            &times;
          </button>
        </div>
      </div>
      <div class="
          table-responsive
          rounded
          shadow-sm
          rounded
          border border-light
          position-relative
        ">
        <table class="table lh-sm table-admin w-100">
          <thead>
            <tr>
              <th>Nama</th>
              <th class="text-center ps-0">Penilaian</th>
              <th class="text-center ps-0">Sijil</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="dependant in computedDependants" :key="dependant._id">
              <td class="text-nowrap text-truncate" style="font-weight: 500;">
                <p :title="dependant.name" class="mb-0 clickable" @click="viewDependant(dependant._id, 'assessments')">{{
                  dependant.name
                }}</p>
              </td>
              <td class="text-center">
                <span class="badge bg-light border text-dark fw-normal p-2 clickable"
                  @click="viewDependant(dependant._id, 'assessments')">
                  {{
                    dependant.dependant_assessments ? dependant.dependant_assessments.length : 0
                  }}
                </span>
              </td>
              <td class="text-center">
                <span class="badge bg-light border text-dark fw-normal p-2 clickable"
                  @click="viewDependant(dependant._id, 'certificates')">
                  {{
                    dependant.dependant_certificates
                    ? dependant.dependant_certificates.length
                    : 0
                  }}
                </span>
              </td>
            </tr>
            <tr v-if="computedDependants.length < 1">
              <td colspan="8" class="text-center fst-italic text-muted">
                No class found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  
<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import DependantDetails from "@/components/DependantDetails";

export default {
  components: {
    LoadingSpinner,
    DependantDetails
  },
  data() {
    return {
      isLoading: false,
      currentMedia: null,
      currentClass: null,
      currentDependant: null,
      dependants: [],
      classes: [],
      search: "",
    };
  },
  watch: {
    currentClass: {
      async handler() {
        if (this.currentClass) {
          await this.getDependants();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    computedDependants() {
      const search = this.search.toLowerCase();

      let result = [...this.dependants];

      if (this.filterStatus) {
        result = result.filter(
          (enquiry) => enquiry.status == this.filterStatus
        );
      }

      return result.filter((enquiry) =>
        enquiry.name.toLowerCase().includes(search)
      );
    },
  },
  methods: {
    updateDependant(e) {
      let dependant = this.dependants.find((d) => d._id == e._id);
      let dependantIndex = this.dependants.findIndex((d) => d._id == e._id);

      if (dependant) {
        this.$set(this.dependants, dependantIndex, e);

        this.handleParamChange();
      }
    },
    viewDependant(dependantId, view) {
      let route = {
        name: 'Officer.ViewStudents',
        params: {
          classId: this.currentClass._id,
          id: dependantId,
        },
        query: {},
      };

      if (view) {
        route.query["view"] = view;
      }
      this.$router.push(route);
    },
    handleParamChange() {
      if (this.$route.params.id) {
        this.currentDependant = this.dependants.find(
          (dependant) => dependant._id == this.$route.params.id
        );
      } else {
        this.currentDependant = null;
      }
    },
    backButton() {
      let schoolId = this.currentClass.school.id

      let route = {
        name: 'Officer.ViewSchools',
        params: {
          id: schoolId,
        },
        query: {},
      };

      this.$router.push(route);
    },
    async getDependants() {
      this.isLoading = true;

      return new Promise(async (resolve) => {
        try {
          const { data } = await this.API.get(
            `dependants?_limit=-1&class=${this.currentClass._id}`
          );

          this.dependants = data;
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;

          resolve();
        }
      });
    },
    async getClasses() {
      this.isLoading = true;

      return new Promise(async (resolve) => {
        try {
          const { data } = await this.API.get(
            `classes?_limit=-1`
          );

          this.classes = data;

          this.currentClass = this.classes.find((cls) => cls._id == this.$route.params.classId);
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;

          resolve();
        }
      });
    },
  },
  mounted() {
    this.getClasses()
    if (this.$route.query && this.$route.query.view) {
      this.currentView = this.$route.query.view;
    }
  },
};
</script>